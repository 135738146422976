@font-face {
  font-family: 'NewsSerif Huge';
  font-weight: 900;
  font-style: normal;
  src: url('resources/fonts/NewsSerifLarge-Bold-BF65b9aaaec9b8c.ttf') format('truetype');
}

/* Define Akzidenz Grotesk Regular */
@font-face {
  font-family: 'Akzidenz Grotesk';
  font-weight: 400; /* Normal */
  font-style: normal;
  src: url('resources/fonts/AkzidenzGrotesk-Regular.otf') format('opentype');
}

/* Define Akzidenz Grotesk Medium */
@font-face {
  font-family: 'Akzidenz Grotesk';
  font-weight: 500; /* Medium */
  font-style: normal;
  src: url('resources/fonts/AkzidenzGrotesk-Medium.otf') format('opentype');
}

body {
  font-family: 'Akzidenz Grotesk', Arial, sans-serif;
  font-size: 1.1em;
  font-weight: 500; /* Medium */
}

body {
  background-color: #F49D7E;
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.App {
  background-color: #F49D7E;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Akzidenz Grotesk', Arial, sans-serif;
  font-weight: 500; /* Medium */
}

p, li, span {
  font-family: 'Akzidenz Grotesk', Arial, sans-serif;
  font-weight: 400; /* Regular */
}

html {
  background-color: #F49D7E;
  margin: 0;
}

.App {
  font-family: Arial, sans-serif;
  background-color: #F49D7E;
}


main {
  padding: 20px;
  background-color: #F49D7E;
}

li {
  list-style: none;
  margin-bottom: 40px;
}

ul {
  padding: 0;
}

header {
  background-color: #F49D7E;
}

.background-container {
  /* background-color: #F49D7E; */
  width: 100%;
  padding: 30px;
}

.svg-container {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  align-items: flex-start; /* Ensure items are top-aligned */
  width: 100%;
  margin: 0px; /* Remove auto margins */
  max-width: 1000px;
  background-color: #1D4B6E;
}

.centered-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.centered-image {
  filter: invert(100%) grayscale(100%) contrast(100%); /* Invert colors and apply grayscale */
  max-width: 300px;
  border-radius: 10px; /* Optional: Add rounded corners to the image */
}

.responsive-svg {
  display: block; /* Treat as block-level element */
  width: 50%;
  height: auto;
  transform: translate(-2px, -1px);
}

.right-aligned {
  margin-left: auto; /* Push the image to the right */
}

.special-title {
  font-family: 'NewsSerif Huge', Arial, sans-serif;
  line-height: 0.9;
  font-size: 4em;
  color: #1D4B6E;
}

h1, h2, h3, h4, h5 {
  font-family: 'NewsSerif Huge', Arial, sans-serif;
}

footer {
  background-color: #1D4B6E;
  color: #F49D7E;
  font-family: 'Akzidenz Grotesk', Arial, sans-serif;
  font-size: 1.1em;
  font-weight: 500; /* Medium */
  padding: 20px;
  text-align: center;
}

.footer-content {
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Akzidenz Grotesk', Arial, sans-serif;
}

.ascii-art {
  font-family: monospace;
  white-space: pre;
  margin: 20px 0;
  color: #F49D7E;
}

.footer-content {
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Akzidenz Grotesk', Arial, sans-serif;
}

.sponsors {
  margin-top: 20px;
  font-family: 'Akzidenz Grotesk', Arial, sans-serif;
}

.sponsors h4 {
  margin-bottom: 10px;
  font-family: 'Akzidenz Grotesk', Arial, sans-serif;
}

.sponsors p {
  margin-bottom: 10px;
  font-family: 'Akzidenz Grotesk', Arial, sans-serif;
}

a {
  color: inherit; /* Ensure the link color matches the surrounding text */
}



/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .background-container {
    background-color: #1D4B6E;
    width: 100%;
    padding: 0px;
  }
}

