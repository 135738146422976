.slide-enter {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
}

.slide-enter-active {
  max-height: 200px; /* Adjust this value based on the expected height of the content */
  opacity: 1;
}

.slide-exit {
  max-height: 200px;
  opacity: 1;
  overflow: hidden;
  transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
}

.slide-exit-active {
  max-height: 0;
  opacity: 0;
}

.image-container {
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative;
}

.member-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}
